export const getSaveEntryDocumentID = (
  documentsByAttributes: Record<string, number[]>,
  loopIdsByDoc: Record<string, number[]>,
  defaultDocumentID: number,
  att: string,
  repeaterID: string | undefined
) => {
  let documentID
  const attDocumentIds = documentsByAttributes[att]
  if (repeaterID) {
    const loopDocumentIds = loopIdsByDoc[repeaterID]
    if (!loopDocumentIds)
      throw new Error(`No loop document ids found for repeater ${repeaterID}`)
    const loopDocumentId = attDocumentIds
      ? loopDocumentIds.find((id) => attDocumentIds.includes(id))
      : loopDocumentIds[0]
    documentID = loopDocumentId
  } else {
    documentID = attDocumentIds ? attDocumentIds[0] : defaultDocumentID
  }
  return documentID ?? defaultDocumentID
}

export const uniqueSharedRepeaters = (
  repeater: string,
  loopIdsByAttsFromDocuments: Record<string, string[]>
) => {
  if (!loopIdsByAttsFromDocuments) return []
  const sharedRepeaters = Object.values(loopIdsByAttsFromDocuments).reduce(
    (acc, val) => {
      if (val.includes(repeater)) acc.push(...val)
      return acc
    },
    []
  )
  return [...new Set(sharedRepeaters)]
}

export const setIterationDocumentID = (
  loopIdsByDoc: Record<string, number[]>,
  repeaterID: string,
  defaultDocumentID: number
) => {
  const documentIDs = loopIdsByDoc[repeaterID]
  let documentID
  if (documentIDs && documentIDs.length) documentID = documentIDs[0]
  return documentID ?? defaultDocumentID
}
