import {getValueAndModificator, toOneBasedCount} from "../../features/QuestionnaireHelpers";
import {DOMEmit} from "../../features/dom_utils";
import {type IQuestionnaireStore} from "../../features/QuestionnaireUtils";
import Utils from "../../features/utils";
const axios = Utils.axios

export const fetchLiveDemoData = ({state, getters, commit, dispatch}) => {
  const dependentAtts = getDependentAtts(state)
  dependentAtts.map(({att, loopCount}) => dispatch('fetch_datasheet_values', {att, loopCount: loopCount - 1}))
  const entries = getEntries(state, getters)
  const modificators = getModificators(state, getters)
  const params = {
    show_cond_logic: state.showConditionLogic,
    entries,
    asts: getters.questionAndOperationAsts,
    modificators,
    loop_counts: toOneBasedCount(state.loop_counts),
    operations: state.operations?.map((operation) =>
      `\`${operation.att} ${operation.cond}\``).join("\n") || []
  }

  axios.post(`/templates/${AvvStore.state.template_id}/live_entries`, params)
    .then(response => response.data)
    .then(data => {
      commit('SET_RESOLVED_CONDITIONS', data.conditions)
      commit('SET_QUESTIONNAIRE_DATA', {entries_values_localized: data.localized_entries, entries_values: data.entries})
      DOMEmit('live-demo-html-update', {html: data.html})
      state.changed_attrs = []
    })
}

const getDependentAtts = (state: IQuestionnaireStore) => {
  return state.changed_attrs.map(({att, loopCount}) => {
    const dependentQuestions = state.questions.filter((depQ: Backend.Questionnaire.IQuestion) => depQ.opts.datasheet_dependencies?.some(dep => dep.attribute === att))
    return dependentQuestions.map((depQ: Backend.Questionnaire.IQuestion) => {
      return {att: depQ.att, loopCount}
    })
  }).flat()
}

const getEntries = (state: IQuestionnaireStore, getters: any) => {
  return Object.keys(state.answers).reduce((acc: Record<string, (string|null)[] | null | string>, att) => {
    const question = getters.questionByAtt(att)
    if(!question) return acc
    const answers = state.answers[att]
    const isRepeaterQuestion = !!question.opts['repeater-id']
    acc[att] = isRepeaterQuestion && Array.isArray(answers)
      ? answers.map((answer: string) => getValueAndModificator(answer, question).value)
      : getValueAndModificator(answers, question).value;
    return acc
  }, {})
}

const getModificators = (state: IQuestionnaireStore, getters: any) => {
  return Object.keys(state.answers).reduce((acc: Record<string, (string|null)[] | null | string>, att) => {
    const question = getters.questionByAtt(att)
    if(!question) return acc
    const answers = state.answers[att]
    acc[att] = Array.isArray(answers)
      ? answers.map((answer: string) => getValueAndModificator(answer, question).modificator)
      : [getValueAndModificator(answers, question).modificator];
    return acc
  }, {})
}

export interface IEntryData {
  value: string | null
  modificator: string | null
  succession: number | null
}

export const getEntriesForBackend = (state: IQuestionnaireStore, getters: any) => {
  return Object.keys(state.answers).reduce((acc: Record<string, IEntryData[]>, att: string ) => {
    const question = getters.questionByAtt(att)
    if(!question) {
      throw new Error(`[LDEntries] Question with attribute ${att} not found`)
    }
    const answers = state.answers[att]
    const isRepeaterQuestion = !!question.opts['repeater-id']
    const entriesData = []
    if(isRepeaterQuestion && Array.isArray(answers)) {
      answers.forEach((answer: string, succession) => {
        const {value, modificator} = getValueAndModificator(answer, question)
        entriesData.push({value, modificator, succession})
      })
    } else {
      const {value, modificator} = getValueAndModificator(answers, question)
      entriesData.push({value, modificator, succession: null})
    }
    acc[att] = entriesData
    return acc
  }, {})
}
