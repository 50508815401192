import {computed, ref, watch, type ComputedRef} from "vue";
import {type DatasheetRowValues} from "../../../add_datasheet_row/types";
import {type IQuestionnaireStore} from "../../../QuestionnaireUtils";
import Utils from "../../../utils";

export interface Datasheet {
  id: number
  name: string
  headers: Header[]
}

export interface Header {
  id: number
  name: string
}

export const useDatasheets = (question: Backend.Questionnaire.IQuestion, store: {state: IQuestionnaireStore }) => {
  const datasheetSearch = ref('')
  const datasheetID = ref(question.opts.datasheet_id ?? 0)
  const datasheets = computed(() => store?.state?.datasheets)
  const selectedDatasheet: ComputedRef<Datasheet | undefined> = computed((): Datasheet | undefined => store?.state?.datasheets?.find(d => d.id == datasheetID.value))
  const filteredDatasheets = computed(() => datasheets.value?.filter(d => d.name.toLowerCase().includes(datasheetSearch.value.toLowerCase())))

  const onSelectDatasheet = (option) => {
    datasheetID.value = option.getAttribute("value")
    question.opts.datasheet_id = datasheetID.value
  }

  return { datasheetSearch, selectedDatasheet, datasheetID, datasheets, filteredDatasheets, onSelectDatasheet }
}

export const useHeaders = (selectedDatasheet: ComputedRef<Datasheet | undefined>, question: Backend.Questionnaire.IQuestion) => {
  const headerSearch = ref('')
  const headerName = ref(question.opts.datasheet_display_header_name ?? 0)
  const headers = computed(() => selectedDatasheet.value?.headers)
  const selectedHeader: ComputedRef<Header | undefined> = computed(() => headers.value?.find(h => h.name == headerName.value))
  const filteredHeaders = computed(() => headers.value?.filter(h => h.name.toLowerCase().includes(headerSearch.value.toLowerCase())))

  const onSelectHeader = (option) => {
    headerName.value = option.getAttribute("value")
    question.opts.datasheet_display_header_name = headerName.value
  }

  return { headerSearch, headerName, selectedHeader, headers, filteredHeaders, onSelectHeader }
}

export const useDependentAttributes = (question: Backend.Questionnaire.IQuestion, store: {state: IQuestionnaireStore }, index: number) => {
  if(!question.opts.datasheet_dependencies) question.opts.datasheet_dependencies = []
  const dependentAttributeSearch = ref('')
  const dependentAttributeName = ref(question.opts.datasheet_dependencies[index]?.attribute ?? '')
  const dependentAttributes = computed(() => store.state.questions.filter(q => q.att !== question.att).map(q => q.att))
  const filteredDependentAttributes = computed(() => dependentAttributes.value.filter(att => att?.toLowerCase().includes(dependentAttributeSearch.value.toLowerCase())))

  const onSelectDependentAttribute = (option) => {
    const value = typeof option === 'string' ? option : option.getAttribute("value");
    dependentAttributeName.value = value
    if(!question.opts.datasheet_dependencies[index]) question.opts.datasheet_dependencies[index] = {}
    question.opts.datasheet_dependencies[index].attribute = dependentAttributeName.value
  }

  return { dependentAttributeName, dependentAttributeSearch, filteredDependentAttributes, onSelectDependentAttribute, dependentAttributes }
}

export const useLookupHeaders = (question: Backend.Questionnaire.IQuestion, store: {state: IQuestionnaireStore }, index: number) => {
  if(!question.opts.datasheet_dependencies) question.opts.datasheet_dependencies = []
  const lookupHeaderName = ref(question.opts.datasheet_dependencies[index]?.header ?? '')
  const lookupSearch = ref('')
  const lookupDatasheet = computed(() => store.state.datasheets?.find(d => d.id == question.opts.datasheet_id))
  const lookupHeaders = computed(() => lookupDatasheet.value?.headers)
  const filteredLookupHeaders = computed(() => lookupHeaders.value?.filter(header => header?.name.toLowerCase().includes(lookupSearch.value.toLowerCase())))

  const onSelectLookupHeader = (option) => {
    lookupHeaderName.value = option.getAttribute('value')
    if(!question.opts.datasheet_dependencies[index]) question.opts.datasheet_dependencies[index] = {}
    question.opts.datasheet_dependencies[index].header = lookupHeaderName.value
  }

  return { lookupHeaderName, lookupSearch,  filteredLookupHeaders, onSelectLookupHeader }
}

// Same as in models/datasheet_cell.rb
export const DATASHEET_DIVIDER_TOKEN = '#='

export const dsSplit = (value: string): string[] => value.split(DATASHEET_DIVIDER_TOKEN, 2)
export const dsCellName = (value: string | undefined): string => typeof value === 'string' ? value.split(DATASHEET_DIVIDER_TOKEN)[0] : ''

export const useNegoDatasheets = (question: Backend.Questionnaire.IQuestion, store: {state: IQuestionnaireStore}, loopCount: number) => {
  if (!['dq', 'ld'].includes(store.state.questionnaire_type)) return {}

  const answer = computed(() => {
    const answer = store.state.answers[question.att]
    return Array.isArray(answer) ? answer[loopCount - 1] : answer
  })

  const shouldRequest = !question.opts?.datasheet_dependencies?.length
  if (shouldRequest) {
    store.dispatch('fetch_datasheet_values', {att: question.att, loopCount: loopCount - 1})
  }

  const values = computed<Array<string> | undefined>(() => {
    const list: Array<string> = store.state.datasheet_values[question.att]?.[(Utils.notANumber(loopCount)) ? 0 : (loopCount - 1)]
    if (typeof list !== 'undefined') {
      const currentAnswer = answer.value
      return Object.values(list.reduce<{[key: string]: string}>((memo, token) => {
        const [value, /* modificator */] = dsSplit(token)
        if (typeof memo[value] === 'undefined' || token === currentAnswer) {
          memo[value] = token
        }
        return memo
      }, {}))
    }
  })

  const filteredValues = computed(() => values.value?.filter((val: string) => dsCellName(val)!.toLowerCase().includes(searchValue.value.toLowerCase())))
  const searchValue = ref('')

  const valueSelect = computed({
    get: () => {
      let value = answer.value
      if (value) {
        const [ /* value */, modificator] = dsSplit(value)
        if (modificator) {
          return value
        } else {
          const str = `${value}${DATASHEET_DIVIDER_TOKEN}`
          if (value = values.value?.find((val: string) => val.startsWith(str))) {
            store.commit('ADD_ANSWER', {value, att: question.att, loopCount})
          }
        }
      }

      return value
    },
    set: (value) => {
      store.commit('ADD_ANSWER', {value, att: question.att, loopCount})
    }
  })

  const onSelectValue = (option: HTMLElement) => {
    valueSelect.value = option.getAttribute('value')
  }

  // Set single answer and refresh all related datasheet question values
  const setValue = async (value: string) => {
    await store.dispatch('reload_datasheet_answers', {
      datasheetId: question.opts.datasheet_id,
      loopCount
    })

    valueSelect.value = value
    searchValue.value = ''
  }

  // Refresh all related datasheet question values & update answers if necessary
  const updateValues = async (newValues: DatasheetRowValues, oldValues: DatasheetRowValues) => {
    await store.dispatch('reload_datasheet_answers', {datasheetId: question.opts.datasheet_id, newValues, oldValues})

    searchValue.value = ''
  }

  const clearValue = () => valueSelect.value = ''

  watch(values, (newValue: Array<string> | undefined) => {
    if (Array.isArray(newValue)) {
      const isEmpty = !newValue.length
      const isNotPresent = !newValue.includes(valueSelect.value) && newValue.length > 1
  
      if ((isEmpty || isNotPresent) && valueSelect.value) {
        valueSelect.value = ''
      }
  
      if (newValue.length === 1 && valueSelect.value !== newValue[0]) {
        valueSelect.value = newValue[0]
      }
    }
  })

  return { values, setValue, updateValues, valueSelect, onSelectValue, searchValue, filteredValues, clearValue }

}

